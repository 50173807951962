@tailwind base;
@tailwind components;
@tailwind utilities;

#root{
  /* padding-top: 64px; */
}
/* Webkit browsers (Chrome, Safari, newer versions of Opera) */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background: #f3f4f6; /* gray-100 */
  border-radius: 3px;
}

::-webkit-scrollbar-thumb {
  background: #d1d5db; /* gray-300 */
  border-radius: 3px;
  transition: background-color 0.2s ease-in-out;
}

::-webkit-scrollbar-thumb:hover {
  background: #9ca3af; /* gray-400 */
}

/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #d1d5db #f3f4f6;
}

/* For Internet Explorer and Edge */
body {
  -ms-overflow-style: none;
}

/* Custom class for elements that need scrollbar */
.custom-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #d1d5db #f3f4f6;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #f3f4f6;
  border-radius: 3px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #d1d5db;
  border-radius: 3px;
  transition: background-color 0.2s ease-in-out;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #9ca3af;
}

/* Hide scrollbar for specific elements if needed */
.hide-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}